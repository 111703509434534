<template>
  <div>
    <div class="additionalFields connectionContainer">
      <div class="httpContentWrapper">
        <div class="mainAttributesContainer">
          <v-divider class="mb-4"></v-divider>
          <HttpHeaderContent
            v-model="parameters"
            v-if="type == 'HTTP'"
            :result="result"
          ></HttpHeaderContent>
          <v-row v-if="type == 'HTTP_RESPONSE'">
            <v-col>
              <httpStatusCode
                v-model="statusCode"
                :result="result"
              ></httpStatusCode>
            </v-col>
          </v-row>
          <HttpTemplateRequest
            v-model="localValue"
            :packetType="type"
            :attrSource="attrSource"
            :result="result"
            :isValid="isValid"
            v-on="$listeners"
          ></HttpTemplateRequest>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import httpStatusCode from "@/components/legacy/httpStatusCode.vue";
import HttpHeaderContent from "@/components/legacy/HttpHeaderContent.vue";
import HttpTemplateRequest from "@/components/legacy/HttpTemplateRequest.vue";

export default {
  components: {
    HttpHeaderContent,
    HttpTemplateRequest,
    httpStatusCode,
  },

  computed: {
    statusCode: {
      get() {
        return this.parameters["status-code"];
      },
      set(newValue) {
        console.log("test");
        this.$set(this.parameters, "status-code", newValue);
        // this.$emit("template-changed", this.templateStoreValue);
        this.updateParameter(this.parameters);
      },
    },
    localValue: {
      get() {
        let value = { parameters: {}, req: {} };

        if (typeof this.templateStoreValue[this.type] != "undefined") {
          value = this.templateStoreValue[this.type];
        }
        return value;
      },
      set(newValue) {
        this.$set(this.templateStoreValue, this.type, newValue);
        this.$emit("template-changed", this.templateStoreValue);
      },
    },
    parameters: {
      get() {
        let parameters = {};
        if (typeof this.templateStoreValue[this.type] != "undefined") {
          parameters = this.templateStoreValue[this.type].parameters;
        }
        return parameters;
      },
      set(newVal) {
        this.updateParameter(newVal);
      },
    },
  },
  methods: {
    updateParameter(newVal) {
      if (typeof this.templateStoreValue[this.type] == "undefined") {
        this.$set(this.templateStoreValue, this.type, {});
      }
      this.$set(this.templateStoreValue[this.type], "parameters", newVal);
      this.$emit("template-changed", this.templateStoreValue);
    },
  },
  props: ["result", "templateStoreValue", "type", "attrSource", "isValid"],
};
</script>