<template>
  <div>
    <v-row>
      <v-col>
        <fieldsValues
            :fieldAttributes="{ 'type': 'text',
                                  'tableAttr': { 'class': 'templateHeaderLabelNOCS' },
                                  'fieldAttrInput': { 'class': 'required tPlus',
                                                   'maxlength': 100 },
                                                   'fieldAttrRow': false,
                                                    'name': 'Name'
                                                 }"
            field="c_name"
            :templateContent="result"
            :value="result.valueLine.c_name"
            :valueLine="result.valueLine"
            :fieldAttrInput="{  }"
            type="text"
        ></fieldsValues>
        <associatedOption
            :fieldAttributes="{ name: 'Code', associatedOption: radiusCode  }"
            type="combobox"
            :fieldAttrInput="{ class: 'required mt-3' }"
            field="code"
            v-model="radiusTemplateCode"
            :templateContent="result"
            :isValid="isValid"
        ></associatedOption>
      </v-col>
      <v-col>
        <fieldsValues
            :fieldAttributes="{ 'type': 'textarea',
                             'fieldAttrInput': { 'class': 'tPlus',
                                                  'rows': '4',
                                                  'cols': '100',
                                                  'maxlength': '800'
                                                },
                                                'fieldAttrRow': false,
                                                'tableAttr': { 'class': 'templateDesc' },
                                                'name': 'Description' }"
            field="c_description"
            :templateContent="result"
            :value="result.valueLine.c_description"
            :valueLine="result.valueLine"
        ></fieldsValues>
      </v-col>
    </v-row>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{ language.componentLabel.labelRequestPacket }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <genericRadiusTemplateRequestAttr
            :attrsArray="reqAttributes"
            :value="getRequestValue(type)"
            :application="type"
            source="t_templates"
            :radiusDynamicPars="radiusDynamicPars"
            @input="updateReq(...arguments)"
            :isTemplate="true"
            :applicationValue="result.additional.radiusEnumValues"
            :result="result"
        ></genericRadiusTemplateRequestAttr>
      </v-container>
    </v-card>
    <div
        v-if="
        typeof typeTemplate != 'undefined' &&
        typeof typeTemplate.res != 'undefined'
      "
    >
      <!--      <div
              v-for="(responseValue, responseType) in typeTemplate.res"
              :key="responseType"
            >-->
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <v-toolbar color="primary" dark flat height="50">
          <v-toolbar-title class="subtitle-2">Response Evaluation</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <genericRadiusTemplateResponseAttr
              :attrsArray="reqAttributes"
              :value="getResponseValue(type)"
              source="t_templates"
              :radiusDynamicPars="radiusDynamicPars"
              @input="updateRes( ...arguments)"
              :isTemplate="true"
              :result="result"
          ></genericRadiusTemplateResponseAttr>
        </v-container>
      </v-card>
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import fieldsValues from "@/commonComponents/fieldsValues.vue";
import genericRadiusTemplateRequestAttr from "@/components/legacy/genericRadiusTemplateRequestAttr.vue";
import genericRadiusTemplateResponseAttr from "@/components/legacy/genericRadiusTemplateResponseAttr.vue";
import {initializeDynamicParams} from "@/js/testHelpers.js";
import options from "@/cfg/options.json";
import {setupAttribute} from "@/js/helper.js";

export default {
  components: {
    genericRadiusTemplateRequestAttr,
    genericRadiusTemplateResponseAttr,
    fieldsValues,
    associatedOption
  },
  props: ["templateStoreValue", "result", "type", "typeTemplate", "isValid"],
  data() {
    return {
      radiusDynamicPars: null,
      radiusCode: options.radiusCode,
      reqAttributes: []
    };
  },
  computed: {
    radiusTemplateCode: {
      get() {
        let result = "";
        if (typeof this.templateStoreValue[this.type] != "undefined" &&
            typeof this.templateStoreValue[this.type].req != "undefined" &&
            typeof this.templateStoreValue[this.type].req.code != "undefined"
        ) {
          result = this.templateStoreValue[this.type].req.code
        }
        return result;
      },
      set(newValue) {
        let templateStoreValue = {...this.templateStoreValue};
        if (typeof templateStoreValue[this.type] == "undefined") {
          this.$set(templateStoreValue, this.type, {});
        }
        if (typeof templateStoreValue[this.type].req == "undefined") {
          this.$set(templateStoreValue[this.type], "req", {});
        }
        if (typeof templateStoreValue[this.type].req.attributes == "undefined") {
          this.$set(templateStoreValue[this.type].req, "attributes", {});
        }
        this.$set(templateStoreValue[this.type].req, "code", newValue);
        this.$emit("template-changed", templateStoreValue);
      },
    },
  },
  mounted() {
    this.getAttributes();
  },
  created() {
    let evalInfo = initializeDynamicParams(
        this.result.additional.additionalEvalParams
    );
    this.radiusDynamicPars = evalInfo.dynamicPars.IPCAN;
  },

  methods: {
    getAttributes() {
      this.services.dictionary.getRadiusAttributes((attributes) => {
        this.reqAttributes = setupAttribute(attributes);
      });
    },
    getRequestValue(type) {
      let requestValue;
      if (typeof this.templateStoreValue[type] != "undefined") {
        requestValue = this.templateStoreValue[type].req;
      }
      return requestValue;
    },
    getResponseValue(type) {
      let responseValue = {};
      if (
          typeof this.templateStoreValue[type] != "undefined" &&
          typeof this.templateStoreValue[type].res != "undefined" &&
          typeof this.templateStoreValue[type].res.eval != "undefined"
      ) {
        responseValue = this.templateStoreValue[type].res.eval;
      }
      return responseValue;
    },
    updateReq(reqValue) {
      let templateStoreValue = {...this.templateStoreValue};
      let request = {};
      for (const [key, valueArray] of Object.entries(reqValue)) {
        valueArray.forEach((value) => {
          if (typeof request[key] != "undefined") {
            request[key].push(value);
          } else {
            request[key] = [value];
          }
        });
      }
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      if (typeof templateStoreValue[this.type].req == "undefined") {
        this.$set(templateStoreValue[this.type], "req", {});
      }
      this.$set(templateStoreValue[this.type].req, "attributes", request);
      this.$emit("template-changed", templateStoreValue);
    },

    updateRes(resValue) {
      let templateStoreValue = {...this.templateStoreValue};
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      let response = {};
      resValue.forEach((value, index) => {
        if (typeof response[index] != "undefined") {
          response[index].push(value);
        } else {
          response[index] = [value];
        }
      });
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      if (typeof templateStoreValue[this.type].res == "undefined") {
        this.$set(templateStoreValue[this.type], "res", {});
      }
      if (typeof templateStoreValue[this.type].res.eval == "undefined") {
        this.$set(templateStoreValue[this.type].res, "eval", []);
      }
      this.$set(templateStoreValue[this.type].res, "eval", resValue);
      this.$emit("template-changed", templateStoreValue);
    },
  },
};
</script>