<template>
  <div>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{
          language.componentLabel.labelRequestPacket
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <diameterRequestAttr
          :attrsArray="result['additional']['diameterAttrs']"
          :value="requestValue"
          :application="type"
          :source="result.additional.source"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateReq(...arguments)"
          :isTemplate="true"
          :result="result"
          :isValid="isValid"
        ></diameterRequestAttr>
      </v-container>
    </v-card>
    <div
      v-if="
        typeof typeTemplate != 'undefined' &&
        typeof typeTemplate.res != 'undefined'
      "
    >
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <v-toolbar color="primary" dark flat height="50">
          <v-toolbar-title class="subtitle-2">{{
            languagePack("testJSConfig", "responsePacket")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <diameterResponseAttr
            :attrsArray="result['additional']['diameterAttrs']"
            :value="responseValue"
            :source="result.additional.source"
            :radiusDynamicPars="radiusDynamicPars"
            @input="updateRes(...arguments)"
            :isTemplate="true"
            :result="result"
          ></diameterResponseAttr>
        </v-container>
      </v-card>
    </div>
  </div>
</template>
<script>
import diameterRequestAttr from "@/components/legacy/diameterRequestAttr.vue";
import diameterResponseAttr from "@/components/legacy/diameterResponseAttr.vue";
import { initializeDynamicParams } from "@/js/testHelpers.js";
//  import templates from "@/cfg/templates.json";
export default {
  data() {
    return { radiusDynamicPars: null };
  },
  components: {
    diameterRequestAttr,
    diameterResponseAttr,
  },
  props: ["templateStoreValue", "result", "type", "typeTemplate","isValid"],
  computed: {
    commands: {
      get() {
        return this.templateStoreValue.commands;
      },
      set(newValue) {
        let templateStoreValue = { ...this.templateStoreValue };
        this.$set(templateStoreValue, "commands", newValue);
        this.$emit("template-changed", templateStoreValue);
      },
    },
    requestValue() {
      let requestValue;
      if (typeof this.templateStoreValue[this.type] != "undefined") {
        requestValue = this.templateStoreValue[this.type].req;
      }
      return requestValue;
    },
    responseValue() {
      let responseValue = {};
      if (
        typeof this.templateStoreValue[this.type] != "undefined" &&
        typeof this.templateStoreValue[this.type].res != "undefined" &&
        typeof this.templateStoreValue[this.type].res.eval != "undefined" &&
        !Array.isArray(this.templateStoreValue[this.type].res.eval)
      ) {
        responseValue = this.templateStoreValue[this.type].res.eval;
      }
      return responseValue;
    },
  },
  created() {
    let evalInfo = initializeDynamicParams(
      this.result.additional.additionalEvalParams
    );
    console.log(evalInfo);
    this.radiusDynamicPars = evalInfo.dynamicPars.IPCAN;
  },
  methods: {
    updateReq(reqValue) {
      let templateStoreValue = { ...this.templateStoreValue };

      let request = {};
      for (const [key, valueArray] of Object.entries(reqValue)) {
        valueArray.forEach((value) => {
          // if (value.source == "t_testcases") {
          if (typeof request[key] != "undefined") {
            request[key].push(value);
          } else {
            request[key] = [value];
          }
          // }
        });
      }
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      this.$set(templateStoreValue[this.type], "req", request);
      this.$emit("template-changed", templateStoreValue);
    },
    updateRes(resValue) {
      let templateStoreValue = { ...this.templateStoreValue };

      let response = {};
      for (const [key, valueArray] of Object.entries(resValue)) {
        valueArray.forEach((value) => {
          //   if (value.source == "t_testcases") {
          if (typeof response[key] != "undefined") {
            response[key].push(value);
          } else {
            response[key] = [value];
          }
          // }
        });
      }
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, {});
      }
      if (typeof templateStoreValue[this.type].res == "undefined") {
        this.$set(templateStoreValue[this.type], "res", {});
      }
      this.$set(templateStoreValue[this.type].res, "eval", response);
      this.$emit("template-changed", templateStoreValue);
    },
  },
};
</script>