<template>
  <div>
    <div
      v-if="
        typeof templates[attrSource] != 'undefined' &&
        typeof templates[attrSource][type] != 'undefined'
      "
    >
      <!--SSH section -->
      <sshTemplate
        v-if="type == 'SSH'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :isValid="isValid"
      ></sshTemplate>
      <radiusTemplate
        v-else-if="type == 'RADIUS_ACCESS' || type == 'RADIUS_ACCOUNTING'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :type="type"
        :typeTemplate="templates[attrSource][type]"
        :isValid="isValid"
      ></radiusTemplate>
        <div  v-else-if="type == 'RADIUS'">
            <genericRadiusTemplate
                    :templateStoreValue="templateStoreValue"
                    @template-changed="updateTemplate"
                    :result="result"
                    :type="type"
                    :typeTemplate="templates[attrSource][type]"
                    :isValid="isValid"
            ></genericRadiusTemplate>
        </div>

      <diameterTemplate
        v-else-if="type == 'DIAMETER'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :type="type"
        :typeTemplate="templates[attrSource][type]"
        :isValid="isValid"
      ></diameterTemplate>
      <eapTemplate
        v-else-if="type == 'EAP'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :type="type"
        :typeTemplate="templates[attrSource][type]"
        :isValid="isValid"
      ></eapTemplate>
      <httpTemplate
        v-else-if="type == 'HTTP' || type == 'HTTP_RESPONSE'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :type="type"
        :typeTemplate="templates[attrSource][type]"
        :attrSource="attrSource"
        :isValid="isValid"
        @changeIsValid="$emit('changeIsValid', $event)"
      ></httpTemplate>
      <seleniumTemplate
        v-else-if="type == 'Selenium'"
        :templateStoreValue="templateStoreValue"
        @template-changed="updateTemplate"
        :result="result"
        :type="type"
        :typeTemplate="templates[attrSource][type]"
        :isValid="isValid"
      ></seleniumTemplate>
      <input
        type="hidden"
        :value="JSON.stringify(templateStoreValue)"
        name="c_obj"
      />
    </div>
  </div>
</template>
<script>
import templates from "@/cfg/templates.json";
import sshTemplate from "@/components/legacy/sshTemplate.vue";
import radiusTemplate from "@/components/legacy/radiusTemplate.vue";
import httpTemplate from "@/components/legacy/httpTemplate.vue";
import diameterTemplate from "@/components/legacy/diameterTemplate.vue";
import eapTemplate from "@/components/legacy/eapTemplate.vue";
import seleniumTemplate from "@/components/legacy/seleniumTemplate.vue";
import genericRadiusTemplate from "@/components/legacy/genericRadiusTemplate.vue";

export default {
  components: {
    sshTemplate,
    radiusTemplate,
    httpTemplate,
    diameterTemplate,
    eapTemplate,
    seleniumTemplate,
    genericRadiusTemplate,
  },
  data: function () {
    return {
      templates: templates,
      parsedValue: {},
      stringfiedValue: this.result.valueLine.c_obj,
      caller: this,
    };
  },
  created() {
    let parsedValue = {};
    try {
      parsedValue = JSON.parse(this.result.valueLine.c_obj);
    } catch (error) {
      parsedValue = {};
    }
    this.parsedValue = parsedValue;
  },
  props: ["result", "isValid"],
  methods: {
    updateTemplate(newVal) {
      this.stringfiedValue = JSON.stringify(newVal);
      this.templateStoreValue = newVal;
      this.parsedValue = newVal;
      this.$emit("input", this.stringfiedValue);
    },
    // manipulateCommandData(key, value) {
    //   console.log("Initial:", this.templateStoreValue);
    //   if (
    //     this.templateStoreValue == null ||
    //     typeof this.templateStoreValue == "undefined" ||
    //     typeof this.templateStoreValue.commands == "undefined"
    //   ) {
    //     this.templateStoreValue = { commands: {} };
    //   }
    //   console.log("value changed ", this.templateStoreValue);
    //   this.$set(this.templateStoreValue.commands, key, value);
    //   this.$emit("stored-value-changed", this.templateStoreValue);
    // }
  },
  computed: {
    type() {
      return this.result.valueLine.c_type;
    },
    templateStoreValue: {
      get() {
        let value = {};
        if (this.result.valueLine.c_obj != this.stringfiedValue) {
          try {
            console.log("parsed again");
            value = JSON.parse(this.result.valueLine.c_obj);
          } catch (error) {
            value = {};
          }
        } else {
          value = this.parsedValue;
        }
        return value;
      },
      set() {},
    },
    attrSource() {
      let attrSource = this.result["additional"]["source"];
      attrSource = attrSource.replace("_history", "");
      return attrSource;
    },
  },
};
</script>