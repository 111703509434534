<template>
  <div>
    <v-card class="" outlined>
      <containerTitle title="Commands"> </containerTitle>
      <div class="pa-3">
        <sshCommandsContainer
          v-model="commands"
          :readOnly="false"
          :result="result"
          :isValid="isValid"
          idPrefix="SSHTemplate"
        ></sshCommandsContainer>
      </div>
    </v-card>
  </div>
</template>
<script>
import sshCommandsContainer from "@/components/legacy/sshCommandsContainer.vue";
import containerTitle from "@/commonComponents/containerTitle.vue";

export default {
  components: {
    sshCommandsContainer,
    containerTitle,
  },
  props: ["templateStoreValue", "result","isValid"],
  computed: {
    commands: {
      get() {
        return this.templateStoreValue.commands;
      },
      set(newValue) {
        let templateStoreValue = { ...this.templateStoreValue };
        this.$set(templateStoreValue, "commands", newValue);
        this.$emit("template-changed", templateStoreValue);
      },
    },
  },
};
</script>