<template>
  <div>
    <lineFieldsArray
        :fieldAttrRow="{}"
        :fields="fields"
        :templateContent="result"
        :value="pairArray"
        @line-field-input="checkInput(...arguments)"
        :headerTitle="false"
        :addable="true"
        :dialogAdder="false"
        addButtTitle="Add Attribute"
        linePrefix="radius_request"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import {v4 as uuidv4} from 'uuid';

export default {
  components: {lineFieldsArray},
  props: {
    application: {},
    applicationValue: {},
    attrsArray: {},
    isTemplate: {type: Boolean, default: false},
    isValid: {type: Boolean, default: false},
    radiusDynamicPars: {},
    result: {},
    source: {},
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    fields() {
      let fields = {
        attribute: {
          type: "combobox",
          associatedOption: this.attrsArray,
          inputFieldFormatter: "radiusTemplateAttributeInput",
          name: "Attribute",
          fieldAttrInput: {class: "required"},
          sort: false,
        },
        content: {
          inputFieldFormatter: "validatedInput",
          application: this.application,
          radiusDynamicPars: this.radiusDynamicPars,
          applicationValue: this.applicationValue,
          type: "text",
          fieldAttrInput: {class: "required validateCases"},
          name: "Value",
          colAttrs: {},
        },
        inputRequired: lineButtons.inputRequired,
        genericRadiusTrash: {...lineButtons.genericRadiusTrash},
        atRanId: {
          type: "hidden",
          colAttrs: {class: "d-none"},
        },
      };
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        if (typeof this.value != 'undefined') {
          let inputs = this.value;
          if (typeof this.value.attributes != 'undefined') {
            inputs = this.value.attributes;
          }
          for (const [key, valueArray] of Object.entries(inputs)) {
            valueArray.forEach((value) => {

              pairArray.push({
                attribute: key,
                ...value,
              });
            });
          }
        }
        return pairArray;
      },
    },
  },
  methods: {
    checkInput(newValueArray) {
      let request = {};
      newValueArray.forEach((line) => {
        //console.log(line);
        let content = line.content;
        let tag = '';
        let attribute = line.attribute;
        if (line.type == "dynamic" && line.content.indexOf("EVAL:") == -1) {
          content = "EVAL:";
        }
        if (line.type == "static" && line.content.indexOf("EVAL:") > -1) {
          content = "";
        }
        if (typeof line.attribute === "object") {
          attribute = line.attribute.attribute;
          tag = line.attribute.tag;
        }
        let insertedLine = {
          //tag: typeof line.tag!='undefined' ? line.tag:"",
          content: content,
          inputRequired: parseInt(line.inputRequired),
          id: typeof line.id != 'undefined' ? line.id : uuidv4()// allow only new not every event!
        };

        if (typeof tag != 'undefined' && tag != '') {
          insertedLine["tag"] = tag;
        } else if (typeof line.tag != 'undefined' && line.tag != '') {
          insertedLine["tag"] = line.tag;
        }
        if (typeof request[attribute] != "undefined") {
          request[attribute].push(insertedLine);
        } else {
          request[attribute] = [insertedLine];
        }
      });
      this.$emit("input", request);
    },
    getFieldAttrRow(valueLine) {
      let attrs = {};
      if (typeof valueLine.color != "undefined") {
        attrs = {style: "color: " + valueLine.color};
      }
      return attrs;
    },
  },
};
</script>