<template>
  <div>
    <buildFieldsRows
      :templateContent="upperParams"
      v-model="upperParamsValues"
      languageIndex="eapTemplate"
    ></buildFieldsRows>
    <v-card class="mb-6 mt-3" elevation="0" outlined>
      <v-toolbar color="primary" dark flat height="50">
        <v-toolbar-title class="subtitle-2">{{language.componentLabel.titleAttr}}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <eapAttrs
          :attrsArray="eapAttributes"
          :value="requestValue"
          :application="type"
          :source="result.additional.source"
          :radiusDynamicPars="radiusDynamicPars"
          @input="updateReq(...arguments)"
          :isTemplate="true"
        ></eapAttrs>
      </v-container>
    </v-card>
    <!-- <div v-if="typeof typeTemplate!='undefined' && typeof typeTemplate.res!='undefined'">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <v-toolbar color="primary" dark flat height="50">
          <v-toolbar-title class="subtitle-2">{{languagePack("testJSConfig","responsePacket")}}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <diameterResponseAttr
            :attrsArray="result['additional']['diameterAttrs']"
            :value="responseValue"
            :source="result.additional.source"
            :radiusDynamicPars="radiusDynamicPars"
            @input="updateRes( ...arguments)"
            :isTemplate="true"
          ></diameterResponseAttr>
        </v-container>
      </v-card>
    </div>-->
  </div>
</template>
<script>
import eapAttrs from "@/components/legacy/eapAttrs.vue";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
//import { initializeDynamicParams } from "@/js/testHelpers.js";
//  import templates from "@/cfg/templates.json";
export default {
  data() {
    return {
      radiusDynamicPars: null,

      upperParams: {
        fields: {
          type: {
            type: "select",
            options: [
              ["AKA", "EAP-AKA"],
              ["AKA'", "EAP-AKA'"],
            ],
            fieldAttrInput: { class: "required " },
          },
          code: {
            type: "radio",
            option: { 1: "Request", 2: "Response" },
            fieldAttrInput: { class: "required" },
            default: "1",
          },
          subtype: {
            type: "select",
            options: [
              "AKA-Challenge",
              "AKA-Authentication-Reject",
              "AKA-Synchronization-Failure",
              "AKA-Identity",
              "SIM-Start",
              "SIM-Challenge",
              "AKA-Notification",
              "AKA-Reauthentication",
              "AKA-Client-Error",
            ],
            fieldAttrInput: { class: "required " },
          },
        },
      },
    };
  },
  components: {
    eapAttrs,
    buildFieldsRows,
  },
  props: ["templateStoreValue", "result", "type", "typeTemplate"],
  computed: {
    eapAttributes() {
      return this.result["additional"]["eapAttributes"];
    },
    storedValue() {
      let storedValue = {};
      if (typeof this.templateStoreValue[this.type] != "undefined") {
        storedValue = this.templateStoreValue[this.type];
      }
      return storedValue;
    },
    upperParamsValues: {
      get() {
        let subtype = "";
        let type = "";
        let code = "1";
        if (typeof this.storedValue != "undefined") {
          if (typeof this.storedValue.req != "undefined") {
            subtype = this.storedValue.req.Subtype;
          }

          if (typeof this.storedValue.Type != "undefined") {
            type = this.storedValue.Type;
          }
          if (typeof this.storedValue.Code != "undefined") {
            code = this.storedValue.Code;
          }
        } else {
          this.updateAttrs({
            type: type,
            code: code,
            subtype: subtype,
          });
        }
        return {
          type: type,
          code: code,
          subtype: subtype,
        };
      },
      set(newVal) {
        this.updateAttrs(newVal);
      },
    },
    // commands: {
    //   get() {
    //     return this.templateStoreValue.commands;
    //   },
    //   set(newValue) {
    //     let templateStoreValue = { ...this.templateStoreValue };
    //     this.$set(templateStoreValue, "commands", newValue);
    //     this.$emit("template-changed", templateStoreValue);
    //   },
    // },
    requestValue() {
      let requestValue = {};

      if (typeof this.storedValue.req != "undefined") {
        requestValue = this.storedValue.req.SubAttributes;
      } else {
        this.updateReq(requestValue);
      }
      return requestValue;
    },
    // responseValue() {
    //   let responseValue = {};
    //   if (
    //     typeof this.templateStoreValue[this.type] != "undefined" &&
    //     typeof this.templateStoreValue[this.type].res != "undefined" &&
    //     typeof this.templateStoreValue[this.type].res.eval != "undefined"
    //   ) {
    //     responseValue = this.templateStoreValue[this.type].res.eval;
    //   }
    //   return responseValue;
    // },
  },
  // created() {
  //   let evalInfo = initializeDynamicParams(
  //     this.result.additional.additionalEvalParams
  //   );
  //   console.log(evalInfo);
  //   this.radiusDynamicPars = evalInfo.dynamicPars.IPCAN;
  // },
  methods: {
    updateAttrs(newVal) {
      let templateStoreValue = { ...this.templateStoreValue };

      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, { req: {} });
      }
      this.$set(templateStoreValue[this.type], "Type", newVal.type);
      this.$set(templateStoreValue[this.type], "Code", newVal.code);
      this.$set(templateStoreValue[this.type].req, "Subtype", newVal.subtype);
      this.$emit("template-changed", templateStoreValue);
    },
    updateReq(reqValue) {
      let templateStoreValue = { ...this.templateStoreValue };
      if (typeof templateStoreValue[this.type] == "undefined") {
        this.$set(templateStoreValue, this.type, { req: {} });
      }
      this.$set(templateStoreValue[this.type].req, "SubAttributes", reqValue);
      this.$emit("template-changed", templateStoreValue);
    },
  },
};
</script>