<template>
  <div> {{id}}
    <draggable
      v-model="localValue"
      group="automation"
      @start="drag = true"
      @end="drag = false"
      handle=".handle"
      class="mb-3"
    >
      <v-card v-for="(object, i) in localValue" :key="i" class="ma-1">
        <v-card-text>
          <v-row class="mb-n6" no-gutters>
            <v-col class="d-flex" style="max-width: 100px; min-width: 138px">
              <div class="col-auto">
                <v-icon class="handle">mdi-drag-vertical</v-icon>
                <trash
                  @click="removeSeleniumCmdList(i)"
                  :result="result"
                ></trash>

                <copyStep
                  :size="20"
                  @click="copyStep(i)"
                  :result="result"
                ></copyStep>

                <insertNext
                  :size="20"
                  @click="insertNext(i)"
                  :result="result"
                ></insertNext>
              </div>
            </v-col>
            <v-col style="max-width: 230px">
              <associatedOption
                type="combobox"
                :fieldAttrInput="{
                  class: 'required',
                  placeholder: language.componentLabel.phSelectCommand,
                }"
                :fieldAttributes="{
                  name: 'Command',
                  associatedOption: seleniumCommands,
                }"
                :value="object.command"
                @input="updateObject(i, 'command', ...arguments)"
                :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col v-if="typeof object.url != 'undefined'" class="ml-1">
              <primaryTextfield
                type="text"
                name="url"
                label="URL"
                :value="object.url"
                :fieldAttrInput="{}"
                @change="updateObject(i, 'url', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="typeof object.name != 'undefined'" class="ml-1">
              <primaryTextfield
                type="text"
                name="name"
                label="Name"
                :value="object.name"
                :fieldAttrInput="{}"
                @change="updateObject(i, 'name', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="typeof object.seconds != 'undefined'" class="ml-1">
              <primaryTextfield
                type="number"
                name="seconds"
                label="Seconds"
                :value="object.seconds"
                :fieldAttrInput="{ class: 'required number' }"
                @change="updateObject(i, 'seconds', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col
              v-if="typeof object.target != 'undefined'"
              style="max-width: 180px"
              class="ml-1"
            >
              <associatedOption
                type="combobox"
                :fieldAttrInput="{ class: 'required' }"
                :fieldAttributes="{
                  name: 'Target Type',
                  associatedOption: targetTypes,
                }"
                :value="object.targetType"
                @input="updateObject(i, 'targetType', ...arguments)"
                :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col v-if="typeof object.id != 'undefined'" class="ml-1">
              <associatedOption
                type="combobox"
                :fieldAttrInput="{ class: 'required' }"
                :fieldAttributes="{
                  name: 'Template',
                  associatedOption: templates,
                }"
                :value="object.id"
                @input="
                  updateObject(i, 'id', ...arguments);
                  templateIdChanged(...arguments);
                "
                :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col v-if="typeof object.target != 'undefined'" class="ml-1">
              <primaryTextfield
                type="text"
                name="target"
                :label="getPlaceholder(object)"
                counter
                :value="object.target"
                :fieldAttrInput="targetAttributes(object)"
                @change="updateObject(i, 'target', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="typeof object.comment != 'undefined'" class="ml-1">
              <primaryTextfield
                type="text"
                name="comment"
                label="Comment"
                counter
                :value="object.comment"
                :fieldAttrInput="targetAttributes(object)"
                @change="updateObject(i, 'comment', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="object.command == 'type'" class="ml-1">
              <primaryTextfield
                type="text"
                name="input"
                label="Input"
                :value="object.input"
                :fieldAttrInput="{}"
                @change="updateObject(i, 'input', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="object.command == 'getAttribute'" class="ml-1">
              <primaryTextfield
                type="text"
                name="name"
                label="Name"
                :value="object.attribute"
                :fieldAttrInput="{ class: 'required' }"
                @change="updateObject(i, 'attribute', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
            <v-col v-if="object.command == 'typePassword'" class="ml-1">
              <password
                type="text"
                name="input"
                label="Password"
                :value="object.input"
                :fieldAttrInput="{}"
                :fieldAttributes="{}"
                @input="updateObject(i, 'input', ...arguments)"
                :templateContent="result"
              ></password>
            </v-col>
            <v-col
              v-if="typeof object.target != 'undefined'"
              style="max-width: 80px"
              class="ml-1"
            >
              <div class="mt-2">{{ language.componentLabel.labelINE }}</div>
            </v-col>
            <v-col
              v-if="typeof object.target != 'undefined'"
              style="max-width: 120px"
              class="ml-1"
            >
              <associatedOption
                type="select"
                :fieldAttrInput="{ class: 'required' }"
                :fieldAttributes="{
                  name: 'Result',
                  associatedOption: options.onTimeoutResult,
                }"
                :value="getResult(object)"
                @input="updateObjectEval(i, 'result', ...arguments)"
                :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col
              v-if="typeof object.target != 'undefined'"
              style="max-width: 290px"
              class="ml-1"
            >
              <associatedOption
                type="select"
                :fieldAttrInput="{ class: 'required' }"
                :fieldAttributes="{ name: 'Action', associatedOption: actions }"
                :value="getAction(object)"
                @input="updateObjectEval(i, 'action', ...arguments)"
                :templateContent="result"
              ></associatedOption>
            </v-col>
            <v-col
              v-if="typeof object.timeout != 'undefined'"
              style="max-width: 80px"
              class="ml-1"
            >
              <primaryTextfield
                type="text"
                name="timeout"
                label="Timeout"
                :value="object.timeout"
                :fieldAttrInput="{}"
                @change="updateObject(i, 'timeout', ...arguments)"
                :templateContent="result"
              ></primaryTextfield>
            </v-col>
          </v-row>
          <lineFieldsArray
            v-if="checkList.includes(object.command)"
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="object.eval"
            @line-field-input="checkInput(i, ...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Check"
            :updateOnChange="true"
            :isValid="isValid"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </draggable>
    <primaryButton
      label="Add Action"
      @click="addSeleniumCmdList()"
      type="button"
      color="primary"
      icon="mdi-plus"
      :disabled="localDisabled || !isValid"
    ></primaryButton>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import primaryButton from "@/commonComponents/primaryButton.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
import associatedOption from "@/commonComponents/associatedOption.vue";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import password from "@/commonComponents/password.vue";
import trash from "@/commonComponents/dynamic/trash.vue";
import options from "@/cfg/options.json";
import lineButtons from "@/cfg/lineButtons.json";
import copyStep from "@/commonComponents/dynamic/copyStep.vue";
import insertNext from "@/commonComponents/dynamic/insertNext.vue";
export default {
  components: {
    primaryTextfield,
    associatedOption,
    draggable,
    primaryButton,
    trash,
    lineFieldsArray,
    password,
    copyStep,
    insertNext,
  },
  data() {
    return {
      options: options,
      placeholder: {
        open: { cmdValue1: " Enter the URL" },

        rtiTextOut: { cmdValue1: " Write the Comments" },
        type: {
          cmdValue1: " Xpath",
          cmdValue2: " Text",
        },
        typePassword: {
          cmdValue1: " Xpath",
          cmdValue2: " Password",
        },
        clickAndWait: {
          cmdValue1: " Xpath",
        },
        rtiGetText: {
          cmdValue1: " Xpath",
          cmdValue2: " Options",
        },
        rtiPause: {
          cmdValue1: " time in secounds",
        },
      },
      seleniumCommands: [
        ["back", "Back"],
        ["click", "Click"],
        ["clear", "Clear Input"],
        ["deleteAllCookies", "Delete all Cookies"],
        ["deleteCookieByName", "Delete Cookie by Name"],
        ["existElement", "Element Exist"],
        ["getAllCookies", "Get all Cookies"],
        ["getText", "Get Text"],
        ["getBody", "Get Body"],
        ["getAttribute", "Get Attribute"],
        ["getCookieByName", "Get Cookie by Name"],
        ["getElementScreenshot", "Get Element Screenshot"],
        ["getPageTitle", "Get Page Title"],
        ["getCurrentUrl", "Get Current URL"],
        ["getValue", "Get Value"],
        ["closeBrowserSession", "Close Browser Session"],
        ["hover", "Hover"],
        ["sourcecode", "Get Source code"],
        ["type", "Input Text"],
        ["typePassword", "Input Password"],
        ["open", "Open URL"],
        ["pause", "Pause"],
        ["refresh", "Refresh Page"],
        ["screenshot", "Take Screenshot"],
        ["textOut", "Write Comment"],
        ["template", "Use Template"],
      ],
      checkList: [
        "getText",
        "getBody",
        "getPageTitle",
        "getCurrentUrl",

        "sourcecode",
        "getValue",
        "getAttribute",
        "getAllCookies",
        "getCookieByName",
      ],
      targetTypes: [
        ["XPATH", "XPATH"],
        ["id", "ID"],
        ["name", "Name"],
        ["class", "Class"],
        ["partial_link_text", "Partial Link Text"],
        ["link_text", "Link Text"],
        ["css", "CSS"],
      ],
      actions: [
        ["stopTestWithScreenshot", "Stop Test with Screenshot"],
        ["stopTestWithoutScreenshot", "Stop Test without Screenshot"],
        ["continueWithScreenshot", "Continue with Screenshot"],
        ["continueWithoutScreenshot", "Continue without Screenshot"],
      ],
      templates: {},
      templateObjects: {},
    };
  },
  props: ["value", "excludeId", "result", "isValid"],
  created() {
    this.getTemplates();
  },
  computed: {
    localDisabled() {
      let localDisabled = false;

      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        localDisabled = true;
      }

      return localDisabled;
    },
    fields() {
      return {
        operator: {
          type: "combobox",
          associatedOption: options.correctOperators,

          name: "Operator",

          fieldAttrInput: { class: "required" },
          default: "contains",
          colAttrs: { style: "max-width:200px" },
        },

        content: {
          type: "text",

          fieldAttrInput: { class: "required" },
          name: "Content",
        },
        else: {
          type: "string",
          string: "else",
          fieldAttrInput: { class: "mt-3" },
          colAttrs: { style: "max-width:50px" },
        },

        result: {
          type: "select",
          associatedOption: options.onTimeoutResult,

          name: "Result",

          fieldAttrInput: { class: "required" },
          default: "error",
          colAttrs: { style: "max-width:150px" },
        },
        action: {
          type: "select",
          associatedOption: this.actions,

          name: "Action",

          fieldAttrInput: { class: "required" },
          default: "stopTestWithScreenshot",
          colAttrs: { style: "max-width:300px" },
        },

        remove: lineButtons.remove,
      };
    },
    localValue: {
      get() {
        let localValue = [];
        if (typeof this.value != "undefined") {
          localValue = this.value;
        } else {
          this.$emit("input", localValue);
        }
        if (localValue.length == 0) {
          localValue.push({});
          this.$emit("input", localValue);
        }
        return localValue;
      },
      set(newValue) {
        newValue.forEach((element, i) => {
          let elementsHandeler = [
            "click",
            "getText",
            "hover",
            "type",
            "typePassword",
            "clear",
            "getAttribute",
            "getValue",
            "getElementScreenshot",
            "existElement",
          ];

          if (elementsHandeler.includes(element.command)) {
            if (typeof newValue[i].timeout == "undefined") {
              this.$set(newValue[i], "timeout", "60");
            }
          } else {
            this.$delete(newValue[i], "timeout");
          }
          if (element.command == "getAttribute") {
            if (typeof newValue[i].attribute == "undefined") {
              this.$set(newValue[i], "attribute", "");
            }
          } else {
            this.$delete(newValue[i], "attribute");
          }
          if (!this.checkList.includes(element.command)) {
            this.$delete(newValue[i], "eval");
          }
          if (element.command == "textOut") {
            if (typeof newValue[i].comment == "undefined") {
              this.$set(newValue[i], "comment", "");
            }
          } else {
            this.$delete(newValue[i], "comment");
          }

          if (element.command == "pause") {
            if (typeof newValue[i].seconds == "undefined") {
              this.$set(newValue[i], "seconds", "10");
            }
            this.$delete(newValue[i], "target");
            this.$delete(newValue[i], "targetType");
            this.$delete(newValue[i], "url");
            this.$delete(newValue[i], "id");
          } else {
            this.$delete(newValue[i], "seconds");

            if (
              element.command == "type" ||
              element.command == "typePassword"
            ) {
              if (typeof newValue[i].input == "undefined") {
                this.$set(newValue[i], "input", "");
              }
            } else {
              this.$delete(newValue[i], "input");
            }
            if (element.command == "open") {
              if (typeof newValue[i].url == "undefined") {
                this.$set(newValue[i], "url", "");
              }
              this.$delete(newValue[i], "target");
              this.$delete(newValue[i], "id");
              this.$delete(newValue[i], "targetType");
            } else if (element.command == "template") {
              if (typeof newValue[i].id == "undefined") {
                this.$set(newValue[i], "id", "");
              }
              this.$delete(newValue[i], "target");
              this.$delete(newValue[i], "targetType");
              this.$delete(newValue[i], "url");
            } else if (
              element.command == "deleteCookieByName" ||
              element.command == "getCookieByName"
            ) {
              if (typeof newValue[i].name == "undefined") {
                this.$set(newValue[i], "name", "");
              }
              this.$delete(newValue[i], "target");
              this.$delete(newValue[i], "targetType");
              this.$delete(newValue[i], "url");
            } else if (
              element.command == "back" ||
              element.command == "deleteAllCookies" ||
              element.command == "refresh" ||
              element.command == "screenshot" ||
              element.command == "sourcecode" ||
              element.command == "getBody" ||
              element.command == "getCurrentUrl" ||
              element.command == "closeBrowserSession" ||
              element.command == "textOut" ||
              element.command == "getPageTitle" ||
              element.command == "getCookieByName" ||
              element.command == "getAllCookies"
            ) {
              this.$delete(newValue[i], "target");
              this.$delete(newValue[i], "targetType");
              this.$delete(newValue[i], "url");
              this.$delete(newValue[i], "id");
            } else {
              if (
                typeof element.command != "undefined" &&
                element.command != "" &&
                element.command != null
              ) {
                if (typeof newValue[i].target == "undefined") {
                  this.$set(newValue[i], "target", "");
                }
                if (typeof newValue[i].targetType == "undefined") {
                  this.$set(newValue[i], "targetType", "XPATH");
                }
              } else {
                this.$delete(newValue[i], "target");
                this.$delete(newValue[i], "targetType");
              }
              this.$delete(newValue[i], "url");
              this.$delete(newValue[i], "id");
            }
            if (typeof newValue[i].target != "undefined") {
              if (
                typeof newValue[i].target != "undefined" &&
                typeof newValue[i].targetNotExists == "undefined"
              ) {
                this.$set(newValue[i], "targetNotExists", {
                  result: "error",
                  action: "stopTestWithScreenshot",
                });
              }
            } else {
              this.$delete(newValue[i], "targetNotExists");
            }
          }
        });
        this.updateAutomation(newValue);
      },
    },
  },
  methods: {
    templateIdChanged(value) {
      this.$emit("template-id-changed", value, this.templateObjects);
    },
    emitTemplateObject() {
      this.$emit("template-selected", this.templateObjects);
    },
    targetAttributes(object) {
      let targetAttributes = { class: "required" };
      if (
        typeof object.targetType != "undefined" &&
        object.targetType == "XPATH"
      ) {
        targetAttributes = { class: "required xPathorVariable" };
      }
      return targetAttributes;
    },
    getTemplates() {
      let request = {
        function: "getSeleniumTemplates",

        requestType: "ajax",
      };
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=configuration&f2=nocsPacketTemplates',
        data: request,
      })
          .then(function (response) {
          caller.templates = response.data.result.json.templateNames;
          caller.$delete(caller.templates, caller.excludeId);
          caller.templateObjects = response.data.result.json.templateObjects;
          //caller.emitTemplateObject();
        })
        .catch(function () {
          //handle error
        });
    },
    getResult(object) {
      let result = "warning";
      if (
        typeof object.targetNotExists != "undefined" &&
        typeof object.targetNotExists.result != "undefined"
      ) {
        result = object.targetNotExists.result;
      }
      return result;
    },
    getAction(object) {
      let action = "stopTestWithScreenshot";
      if (
        typeof object.targetNotExists != "undefined" &&
        typeof object.targetNotExists.action != "undefined"
      ) {
        action = object.targetNotExists.action;
      }
      return action;
    },
    getPlaceholder(command) {
      // if (typeof this.placeholder[command] != "undefined") {
      let text = command.targetType;
      this.targetTypes.some((element) => {
        if (element[0] == text) {
          text = element[1];
          return true;
        }
      });

      return text;
      //  }
    },

    checkInput(i, value) {
      let automationCommandList = [...this.value];
      //console.log(i, index, value);
      if (typeof automationCommandList[i]["eval"] == "undefined") {
        this.$set(automationCommandList[i], "eval", []);
      }
      this.$set(automationCommandList[i], "eval", value);

      this.localValue = automationCommandList;
    },
    updateObjectEval(i, index, value) {
      let automationCommandList = [...this.value];
      //console.log(i, index, value);
      if (typeof automationCommandList[i]["targetNotExists"] == "undefined") {
        this.$set(automationCommandList[i], "targetNotExists", {});
      }
      this.$set(automationCommandList[i]["targetNotExists"], index, value);

      this.localValue = automationCommandList;
    },
    updateObject(i, index, value) {
      let automationCommandList = [...this.value];
      //console.log(i, index, value);
      // automationCommandList[i][index] = value;
      this.$set(automationCommandList[i], index, value);
      this.localValue = automationCommandList;
    },
    removeSeleniumCmdList(i) {
      //console.log(this.value);
      let automationCommandList = [...this.value];

      automationCommandList.splice(i, 1);
      if (automationCommandList.length == 0) {
        automationCommandList.push({ command: "" });
      }
      this.localValue = automationCommandList;
    },
    copyStep(index) {
      let localValue = [...this.value];

      let step = { ...localValue[index] };
      localValue.splice(index + 1, 0, step);
      this.$emit("input", localValue);
    },
    insertNext(index) {
      let localValue = [...this.value];

      localValue.splice(index + 1, 0, {});
      this.$emit("input", localValue);
    },
    addSeleniumCmdList() {
      //console.log(this.value);
      let automationCommandList = [...this.value];

      automationCommandList.push({ command: "" });
      this.localValue = automationCommandList;
    },
    updateAutomation(value) {
      this.$emit("input", value);
    },
  },
};
</script>