<template>
  <div>
    <lineFieldsArray
      :fieldAttrRow="getFieldAttrRow"
      :fields="fields"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="false"
      :addable="true"
      :dialogAdder="false"
      addButtTitle="Add Evaluation"
      :updateOnChange="true"
      :templateContent="result"
    ></lineFieldsArray>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineButtons from "@/cfg/lineButtons.json";
import { v4 as uuidv4 } from 'uuid';

export default {
  components: { lineFieldsArray },
  props: {
    attrsArray: {},
    isTemplate: { type: Boolean, default: false },
    isValid: { type: Boolean, default: false },
    radiusDynamicPars: {},
    result: { type: Object },
    source: {},
    value: {
     },
  },
  data() {
    return {
    };
  },
  computed: {
    fields() {
       let fields = {
        attribute: {
               type: "combobox",
               associatedOption: this.attrsArray,
               inputFieldFormatter: "radiusTemplateAttributeInput",
               name: "Type",
               fieldAttrInput: { class: "required",additionalTunnelTag: ["any","Any"] },
               sort: false,
               colAttrs: { cols:4  },
           },
      /*   tag: {
           type: "combobox",
           inputFieldFormatter: "tunnelTags",
           name: "Tag",
           colAttrs: { cols: 2 },
           fieldAttrInput: { additionalTunnelTag: ["any","Any"] },
         },*/
        operator: {
          // input: false,
          inputFieldFormatter: "attributesOperators",
          //colAttrs: { style: "max-width: 23px;min-width: 35px;", class: "mt-1" }
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "text",
          name: "Value",
          inputFieldFormatter: "evalContentFormatter",
          fieldAttrInput: { class: "required" },
        },
         else: {
           input: false,
           type: "string",
           string: "else",
           colAttrs: {
             style: "max-width: 27px;color:black!important;",
           },
         },
        result: {
          type: "select",
          name: "Result",
          options: options.ldapResult,
          colAttrs: { cols: 1 },
          default: "error",
          fieldAttrInput: { class: "required" },
        },
        action: {
          type: "select",
          name: "Action",
          options: options.evalAction,
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
         info: {
           type: "text",
           name: "Info",
           fieldAttrInput: { class: "" ,maxlength:'100' },
         },
        genericRadiusTrash: { ...lineButtons.genericRadiusTrash },
      };
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];
        let value = [];
        if( typeof this.value.add!='undefined' && Object.keys(this.value.add).length> 0 ) {
          value = this.value.add
        }
        if( typeof this.value!='undefined' && Object.keys(this.value).length> 0 ) {
          value = this.value
        }
        for (const valueArray  of value) {
           pairArray.push(valueArray);
         }
       return pairArray;
      },
    },
  },
  created() {
  },
  methods: {
    checkInput(newValueArray) {
      let request = [];
      newValueArray.forEach((line) => {
        let tag='';
        let attribute = line.attribute;
        if(typeof line.attribute==="object"){
          attribute = line.attribute.attribute;
          tag = line.attribute.tag;
        }
        let insertedLine = {
          //tag: typeof line.tag!='undefined' ? line.tag:"",
          info:typeof line.info!='undefined' ? line.info:"",
          content: line.content,
          operator: line.operator,
          result: line.result,
          action: line.action,
          attribute:attribute,
          id: typeof line.id!='undefined' ? line.id:uuidv4()// allow only new not every event!
        };
        if(typeof tag!='undefined' && tag!=''){
          insertedLine["tag"]=tag;
        }else if(typeof line.tag!='undefined' && line.tag!=''){
          insertedLine["tag"]=line.tag;
        }
         request.push(insertedLine);
      });
      this.$emit("input", request);
    },
    getFieldAttrRow(valueLine) {
      let attrs = {};
      if (typeof valueLine.color != "undefined") {
        attrs = { style: "color: " + valueLine.color };
      }
      return attrs;
    },
  },
};
</script>