var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',[_c('fieldsValues',{attrs:{"fieldAttributes":{ 'type': 'text',
                                'tableAttr': { 'class': 'templateHeaderLabelNOCS' },
                                'fieldAttrInput': { 'class': 'required tPlus',
                                                 'maxlength': 100 },
                                                 'fieldAttrRow': false,
                                                  'name': 'Name'
                                               },"field":"c_name","templateContent":_vm.result,"value":_vm.result.valueLine.c_name,"valueLine":_vm.result.valueLine,"fieldAttrInput":{  },"type":"text"}}),_c('associatedOption',{attrs:{"fieldAttributes":{ name: 'Code', associatedOption: _vm.radiusCode  },"type":"combobox","fieldAttrInput":{ class: 'required mt-3' },"field":"code","templateContent":_vm.result,"isValid":_vm.isValid},model:{value:(_vm.radiusTemplateCode),callback:function ($$v) {_vm.radiusTemplateCode=$$v},expression:"radiusTemplateCode"}})],1),_c('v-col',[_c('fieldsValues',{attrs:{"fieldAttributes":{ 'type': 'textarea',
                           'fieldAttrInput': { 'class': 'tPlus',
                                                'rows': '4',
                                                'cols': '100',
                                                'maxlength': '800'
                                              },
                                              'fieldAttrRow': false,
                                              'tableAttr': { 'class': 'templateDesc' },
                                              'name': 'Description' },"field":"c_description","templateContent":_vm.result,"value":_vm.result.valueLine.c_description,"valueLine":_vm.result.valueLine}})],1)],1),_c('v-card',{staticClass:"mb-6 mt-3",attrs:{"elevation":"0","outlined":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","height":"50"}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.language.componentLabel.labelRequestPacket))])],1),_c('v-container',[_c('genericRadiusTemplateRequestAttr',{attrs:{"attrsArray":_vm.reqAttributes,"value":_vm.getRequestValue(_vm.type),"application":_vm.type,"source":"t_templates","radiusDynamicPars":_vm.radiusDynamicPars,"isTemplate":true,"applicationValue":_vm.result.additional.radiusEnumValues,"result":_vm.result},on:{"input":function($event){return _vm.updateReq(...arguments)}}})],1)],1),(
      typeof _vm.typeTemplate != 'undefined' &&
      typeof _vm.typeTemplate.res != 'undefined'
    )?_c('div',[_c('v-card',{staticClass:"mb-6 mt-3",attrs:{"elevation":"0","outlined":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":"","height":"50"}},[_c('v-toolbar-title',{staticClass:"subtitle-2"},[_vm._v("Response Evaluation")])],1),_c('v-container',[_c('genericRadiusTemplateResponseAttr',{attrs:{"attrsArray":_vm.reqAttributes,"value":_vm.getResponseValue(_vm.type),"source":"t_templates","radiusDynamicPars":_vm.radiusDynamicPars,"isTemplate":true,"result":_vm.result},on:{"input":function($event){return _vm.updateRes( ...arguments)}}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }