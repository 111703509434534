<template>
  <div>
    <selenium
            v-model="localValue"
            :excludeId="result.valueLine.n_id"
            :isValid="isValid"
    ></selenium>
  </div>
</template>
<script>
import selenium from "@/components/legacy/selenium.vue";

export default {
  components: {
    selenium,
  },

  computed: {
    // statusCode: {
    //   get() {
    //     return this.parameters["status-code"];
    //   },
    //   set(newValue) {
    //     this.$set(this.parameters, "status-code", newValue);
    //     // this.$emit("template-changed", this.templateStoreValue);
    //   },
    // },
    localValue: {
      get() {
        let value = [];

        if (
          typeof this.templateStoreValue != "undefined" &&
          Array.isArray(this.templateStoreValue)
        ) {
          value = this.templateStoreValue;
        }
        return value;
      },
      set(newValue) {
        //this.$set(this.templateStoreValue, this.type, newValue);
        //console.log("template-changed", newValue);
        this.$emit("template-changed", newValue);
      },
    },
    // parameters: {
    //   get() {
    //     let parameters = {};
    //     if (typeof this.templateStoreValue[this.type] != "undefined") {
    //       parameters = this.templateStoreValue[this.type].parameters;
    //     }
    //     return parameters;
    //   },
    //   set(newVal) {
    //     if (typeof this.templateStoreValue[this.type] == "undefined") {
    //       this.$set(this.templateStoreValue, this.type, {});
    //     }
    //     this.$set(this.templateStoreValue[this.type], "parameters", newVal);
    //     this.$emit("template-changed", this.templateStoreValue);
    //   },
    // },
  },
  methods: {},
  props: ["result", "templateStoreValue", "type","isValid"],
};
</script>