<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="value"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";

export default {
  components: { fieldsRows },
  data() {
    let data = {
      versions: [
        ["1.0", "HTTP 1.0"],
        ["1.1", "HTTP 1.1"],
        ["2.0", "HTTP 2"],
      ],
      methods: ["GET", "POST", "PUT", "DELETE", "PATCH"],
    };
    data.methods.sort();
    return data;
  },
  props: ["value", "disabled", "result"],
  computed: {
    templateContent() {
      // let parameters = this.value;
      // console.log(parameters);
      // let uri = "",
      //   version = "",
      //   type = "",
      //   SSL = 0;
      // if (
      //   typeof parameters != "undefined" &&
      //   typeof parameters.uri != "undefined"
      // ) {
      //   uri = parameters.uri;
      // }
      // if (
      //   typeof parameters != "undefined" &&
      //   typeof parameters.version != "undefined"
      // ) {
      //   version = parameters.version;
      // }
      // if (
      //   typeof parameters != "undefined" &&
      //   typeof parameters.type != "undefined"
      // ) {
      //   type = parameters.type;
      // }
      // if (
      //   typeof parameters != "undefined" &&
      //   typeof parameters.SSL != "undefined"
      // ) {
      //   SSL = parameters.SSL;
      // }
      let templateContent = {
        fields: {
          version: {
            type: "select",

            associatedOption: this.versions,
            fieldAttrInput: { class: "httpVersionSelector required" },
          },
          type: {
            type: "select",

            associatedOption: this.methods,
            fieldAttrInput: { class: "required" },
          },
          SSL: {
            type: "checkbox",
          },
          uri: {
            type: "text",

            fieldAttrInput: { name: "URI", class: "URIinput" },
          },
        },
        enableAdd: false,
        contentID: this.result.additionlFormAtts["data-className"],
      };
      if (this.result.disableAllInputs || this.disabled) {
        templateContent.fields.version.fieldAttrInput.class =
          "httpVersionSelector";
        templateContent.fields.type.fieldAttrInput.class =
          "httpVersionSelector";
      }
      // console.log("read only");
      // if (this.disabled) {
      //   let caller = this;
      //   Object.keys(templateContent.fields).forEach((key) => {
      //     if (
      //       typeof templateContent.fields[key].fieldAttrInput == "undefined"
      //     ) {
      //       caller.$set(templateContent.fields[key], "fieldAttrInput", {});
      //     }
      //     caller.$set(
      //       templateContent.fields[key].fieldAttrInput,
      //       "readonly",
      //       true
      //     );
      //     caller.$set(
      //       templateContent.fields[key].fieldAttrInput,
      //       "disabled",
      //       true
      //     );
      //   });
      // }
      templateContent.fields = this.languageHelpProvider(
        templateContent.fields,
        "HTTP_step"
      );
      templateContent.disableAllInputs =
        this.result.disableAllInputs || this.disabled;
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(field, value) {
      console.log("changed", field, value);
      let localValue = { ...this.value };
      this.$set(localValue, field, value);
      this.$emit("input", localValue);
      this.$nextTick(function () {
        this.$emit("revalidate");
      });
    },
  },
};
</script>
