<template>
  <div class="requestTabsContainer">
    <HTTPTabs :packetType="packetType" :requestType="paraType" v-on="$listeners">
      <template v-slot:tabsContent="data">
        <div v-if="data.item == 'Header'">
          <attributeValueAdderContent :value="storedHeader" :headerTitle="true" :attrSource="attrSource"
                                      @input="updateRequestIndex('Header', ...arguments)" :result="result"
                                      :isValid="isValid" linePrefix="HttpTemplateRequest_header"
                                      :indexName="language.HTTP_step.fieldName"
                                      :hideDetails="true"></attributeValueAdderContent>
        </div>
        <div v-if="data.item == 'Parameter'">
          <div v-if="packetType != 'HTTP_RESPONSE'">
            <attributeValueAdderContentParameterTab :value="parametersAttributes" :headerTitle="true"
                                                    @input="updateRequestIndex('parameters', ...arguments)"
                                                    :attrSource="attrSource" :isTemplate="true" :result="result"
                                                    :isValid="isValid"></attributeValueAdderContentParameterTab>
          </div>
        </div>
        <div v-if="data.item == 'Body'">
          <div class="expandableBodyContainer">
            <HTTPBodyContent :contentType="contentType" :packetType="packetType" :storedBody="storedBody"
                             @stored-body-changed="updateRequestIndex('body', ...arguments)"
                             @content-type-changed="updateContentType" :result="result" :isValid="isValid"
                             @changeIsValid="$emit('changeIsValid', $event)" linePrefix="HttpTemplateRequest_body"
                             :draggable="true"></HTTPBodyContent>
          </div>
        </div>
      </template>
    </HTTPTabs>
  </div>
</template>
<script>
import HTTPTabs from "@/components/legacy/HTTPTabs.vue";
import attributeValueAdderContent from "@/components/legacy/attributeValueAdderContent.vue";
import attributeValueAdderContentParameterTab from "@/components/legacy/attributeValueAdderContentParameterTab.vue";
import HTTPBodyContent from "@/components/legacy/HTTPBodyContent.vue";

export default {
  components: {
    HTTPTabs,
    attributeValueAdderContent,
    attributeValueAdderContentParameterTab,
    HTTPBodyContent,
  },
  props: ["value", "packetType", "attrSource", "result", "isValid"],
  methods: {
    updateRequestIndex(index, newval) {
      let localStoredStep = {...this.value};
      if (typeof localStoredStep["req"] == "undefined") {
        this.$set(localStoredStep, "req", {});
      }
      this.$set(localStoredStep["req"], index, newval);
      this.$emit("input", localStoredStep);
    },

    updateContentType(contentType) {
      let localStoredStep = {...this.value};
      this.$set(localStoredStep["parameters"], "content-type", contentType);
      this.$emit("input", localStoredStep);
    },
  },
  computed: {
    paraType() {
      return this.value.parameters.type;
    },
    storedBody() {
      //return this.value["req"]["body"];
      let body = {};
      if (typeof this.value["req"] != "undefined") {
        body = this.value["req"]["body"];
      }
      return body;
    },
    storedHeader() {
      let header = {};
      if (typeof this.value["req"] != "undefined") {
        header = this.value["req"]["Header"];
      }
      return header;
    },
    parameters: {
      get() {
        return this.value.parameters;
      },
      set() {
        // this.$set(this.value, "parameters", newVal);
        // this.$emit("stored-value-changed", this.templateStoreValue);
      },
    },
    contentType() {
      let contentType = "";

      // }
      if (
          typeof this.parameters != "undefined" &&
          typeof this.parameters["content-type"] != "undefined"
      ) {
        contentType = this.parameters["content-type"];
      }
      return contentType;
    },

    parametersAttributes() {
      // return this.value.req.parameters;

      let parameters = [];
      if (typeof this.value["req"] != "undefined") {
        parameters = this.value["req"]["parameters"];
      }
      return parameters;
    },
  },
};
</script>